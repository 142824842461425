:where(body[data-setel-page]) .slick-loading .slick-list, :host([data-setel-widget]) .slick-loading .slick-list, setel-widget-reach-portal:not(#\#) .slick-loading .slick-list {
  background: #fff url("ajax-loader.gif") 50% no-repeat;
}

@font-face {
  font-family: slick;
  font-style: normal;
  font-weight: 400;
  src: url("fonts/slick.eot");
  src: url("fonts/slick.eot?#iefix") format("embedded-opentype"), url("fonts/slick.woff") format("woff"), url("fonts/slick.ttf") format("truetype"), url("fonts/slick.svg#slick") format("svg");
}

:where(body[data-setel-page]) .slick-next, :host([data-setel-widget]) .slick-next, setel-widget-reach-portal:not(#\#) .slick-next, :where(body[data-setel-page]) .slick-prev, :host([data-setel-widget]) .slick-prev, setel-widget-reach-portal:not(#\#) .slick-prev {
  cursor: pointer;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

:where(body[data-setel-page]) .slick-next, :host([data-setel-widget]) .slick-next, setel-widget-reach-portal:not(#\#) .slick-next, :where(body[data-setel-page]) .slick-next:focus, :host([data-setel-widget]) .slick-next:focus, setel-widget-reach-portal:not(#\#) .slick-next:focus, :where(body[data-setel-page]) .slick-next:hover, :host([data-setel-widget]) .slick-next:hover, setel-widget-reach-portal:not(#\#) .slick-next:hover, :where(body[data-setel-page]) .slick-prev, :host([data-setel-widget]) .slick-prev, setel-widget-reach-portal:not(#\#) .slick-prev, :where(body[data-setel-page]) .slick-prev:focus, :host([data-setel-widget]) .slick-prev:focus, setel-widget-reach-portal:not(#\#) .slick-prev:focus, :where(body[data-setel-page]) .slick-prev:hover, :host([data-setel-widget]) .slick-prev:hover, setel-widget-reach-portal:not(#\#) .slick-prev:hover {
  color: #0000;
  background: none;
  outline: none;
}

:where(body[data-setel-page]) .slick-next:focus:before, :host([data-setel-widget]) .slick-next:focus:before, setel-widget-reach-portal:not(#\#) .slick-next:focus:before, :where(body[data-setel-page]) .slick-next:hover:before, :host([data-setel-widget]) .slick-next:hover:before, setel-widget-reach-portal:not(#\#) .slick-next:hover:before, :where(body[data-setel-page]) .slick-prev:focus:before, :host([data-setel-widget]) .slick-prev:focus:before, setel-widget-reach-portal:not(#\#) .slick-prev:focus:before, :where(body[data-setel-page]) .slick-prev:hover:before, :host([data-setel-widget]) .slick-prev:hover:before, setel-widget-reach-portal:not(#\#) .slick-prev:hover:before {
  opacity: 1;
}

:where(body[data-setel-page]) .slick-next.slick-disabled:before, :host([data-setel-widget]) .slick-next.slick-disabled:before, setel-widget-reach-portal:not(#\#) .slick-next.slick-disabled:before, :where(body[data-setel-page]) .slick-prev.slick-disabled:before, :host([data-setel-widget]) .slick-prev.slick-disabled:before, setel-widget-reach-portal:not(#\#) .slick-prev.slick-disabled:before {
  opacity: .25;
}

:where(body[data-setel-page]) .slick-next:before, :host([data-setel-widget]) .slick-next:before, setel-widget-reach-portal:not(#\#) .slick-next:before, :where(body[data-setel-page]) .slick-prev:before, :host([data-setel-widget]) .slick-prev:before, setel-widget-reach-portal:not(#\#) .slick-prev:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  opacity: .75;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

:where(body[data-setel-page]) .slick-prev, :host([data-setel-widget]) .slick-prev, setel-widget-reach-portal:not(#\#) .slick-prev {
  left: -25px;
}

:where(body[data-setel-page]) [dir="rtl"] .slick-prev, :host([data-setel-widget]) [dir="rtl"] .slick-prev, setel-widget-reach-portal:not(#\#) [dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

:where(body[data-setel-page]) .slick-prev:before, :host([data-setel-widget]) .slick-prev:before, setel-widget-reach-portal:not(#\#) .slick-prev:before {
  content: "←";
}

:where(body[data-setel-page]) [dir="rtl"] .slick-prev:before, :host([data-setel-widget]) [dir="rtl"] .slick-prev:before, setel-widget-reach-portal:not(#\#) [dir="rtl"] .slick-prev:before {
  content: "→";
}

:where(body[data-setel-page]) .slick-next, :host([data-setel-widget]) .slick-next, setel-widget-reach-portal:not(#\#) .slick-next {
  right: -25px;
}

:where(body[data-setel-page]) [dir="rtl"] .slick-next, :host([data-setel-widget]) [dir="rtl"] .slick-next, setel-widget-reach-portal:not(#\#) [dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

:where(body[data-setel-page]) .slick-next:before, :host([data-setel-widget]) .slick-next:before, setel-widget-reach-portal:not(#\#) .slick-next:before {
  content: "→";
}

:where(body[data-setel-page]) [dir="rtl"] .slick-next:before, :host([data-setel-widget]) [dir="rtl"] .slick-next:before, setel-widget-reach-portal:not(#\#) [dir="rtl"] .slick-next:before {
  content: "←";
}

:where(body[data-setel-page]) .slick-dotted.slick-slider, :host([data-setel-widget]) .slick-dotted.slick-slider, setel-widget-reach-portal:not(#\#) .slick-dotted.slick-slider {
  margin-bottom: 30px;
}

:where(body[data-setel-page]) .slick-dots, :host([data-setel-widget]) .slick-dots, setel-widget-reach-portal:not(#\#) .slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

:where(body[data-setel-page]) .slick-dots li, :host([data-setel-widget]) .slick-dots li, setel-widget-reach-portal:not(#\#) .slick-dots li {
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

:where(body[data-setel-page]) .slick-dots li, :host([data-setel-widget]) .slick-dots li, setel-widget-reach-portal:not(#\#) .slick-dots li, :where(body[data-setel-page]) .slick-dots li button, :host([data-setel-widget]) .slick-dots li button, setel-widget-reach-portal:not(#\#) .slick-dots li button {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

:where(body[data-setel-page]) .slick-dots li button, :host([data-setel-widget]) .slick-dots li button, setel-widget-reach-portal:not(#\#) .slick-dots li button {
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

:where(body[data-setel-page]) .slick-dots li button:focus, :host([data-setel-widget]) .slick-dots li button:focus, setel-widget-reach-portal:not(#\#) .slick-dots li button:focus, :where(body[data-setel-page]) .slick-dots li button:hover, :host([data-setel-widget]) .slick-dots li button:hover, setel-widget-reach-portal:not(#\#) .slick-dots li button:hover {
  outline: none;
}

:where(body[data-setel-page]) .slick-dots li button:focus:before, :host([data-setel-widget]) .slick-dots li button:focus:before, setel-widget-reach-portal:not(#\#) .slick-dots li button:focus:before, :where(body[data-setel-page]) .slick-dots li button:hover:before, :host([data-setel-widget]) .slick-dots li button:hover:before, setel-widget-reach-portal:not(#\#) .slick-dots li button:hover:before {
  opacity: 1;
}

:where(body[data-setel-page]) .slick-dots li button:before, :host([data-setel-widget]) .slick-dots li button:before, setel-widget-reach-portal:not(#\#) .slick-dots li button:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  content: "•";
  opacity: .25;
  text-align: center;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

:where(body[data-setel-page]) .slick-dots li.slick-active button:before, :host([data-setel-widget]) .slick-dots li.slick-active button:before, setel-widget-reach-portal:not(#\#) .slick-dots li.slick-active button:before {
  color: #000;
  opacity: .75;
}
